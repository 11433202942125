const Education = (props) => {
    if (props.data.education === undefined) return;
    return (
        <>
            {props.data.education.map((edu => {
                return (
                    <div className="shadow rounded card col-sm-12 col-md-5 m-2" key={edu.school}>
                        <div className="card-body">
                            <h5 className="card-title">{edu.school}</h5>
                            <h6 className="card-subtitle mb-2 text-muted">{edu.start_year} - {edu.end_year}</h6>
                            <p className="card-text">
                                {edu.degree_level !== "Diploma" ?
                                <>{edu.degree_level} of {edu.major}</>
                                :
                                <>{edu.major}</>
                                }
                            </p>
                        </div>
                    </div>

                );
            }))}
            {props.data.credentials.map((creds => {
                return (
                    <div className="card col-md-3 col-sm-12 m-2 shadow" key={creds.name}>
                        <div className="card-body">
                            <h5 className="card-title">{creds.company}</h5>
                            <p className="card-text">{creds.name}</p>
                        </div>
                    </div>

                );
            }))}

        <div className="row col-12 m-2">
            <button className="btn btn-sm btn-secondary mx-auto shadow" 
            type="button" data-toggle="collapse" data-target="#apiEducationResponse" aria-expanded="false" aria-controls="apiEducationResponse">View API Response Data</button>    
        </div>
        <div className="row col-12 m-2">
            <div className="collapse col-12" id="apiEducationResponse">
                <div className="card card-body col-12">
                    <pre className="rounded prettyprint lang-js">
                        {JSON.stringify(props.data.education, null, 2)}
                        {window.prettyPrint()}
                    </pre>
                </div>
            </div>
        </div>
        </>
    );

}

export default Education;