const Projects = (props) => {
    if (props.data.projects === undefined) return;
    return (
        <>
            {props.data.projects.map((proj => {
                let hasLogo = proj.logo !== undefined ? true : false;
                
                const img = hasLogo ?
                (<img className="mx-auto h-25 w-25" src={proj.logo}></img>) :
                (<></>);

                const img_spacing = hasLogo ? "card col-11 m-2 shadow" : "card col-md-5 col-sm-11 m-2 shadow";

                return (
                    <div className={img_spacing} key={proj.name}>
                        <div className="card-body">
                            <h5 className="card-title">{proj.name}</h5>
                            <h6 className="card-subtitle mb-2 text-muted">{proj.subtitle}</h6>
                            <h4>
                                {proj.stack.map((lang => {
                                    return (<span key={lang} className="badge badge-pill badge-primary mx-1">{lang}</span>)
                                }))}
                            </h4>
                            <p className="card-text">
                                <span className="text-justify">
                                    {proj.description}
                                </span>
                            </p>
                            <a href={proj.url} className="card-link">View Project</a>
                        </div>
                    </div>

                );
            }))}
        <div className="row col-12 m-2">
            <button className="btn btn-sm btn-secondary mx-auto shadow" 
            type="button" data-toggle="collapse" data-target="#apiProjectsResponse" aria-expanded="false" aria-controls="apiProjectsResponse">View API Response Data</button>    
        </div>
        <div className="row col-12 m-2">
            <div className="collapse col-12" id="apiProjectsResponse">
                <div className="card card-body col-12">
                    <pre className="rounded prettyprint lang-js">
                        {JSON.stringify(props.data.projects, null, 2)}
                    </pre>
                </div>
            </div>
        </div>
        </>
    );
}

export default Projects;