import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faGithub,
    faLinkedin,
    faGoogle
} from "@fortawesome/free-brands-svg-icons";

import { faFile, faAt } from "@fortawesome/free-solid-svg-icons";

const Socials = (props) => {
    if (props.data === undefined) return;

    return (
        <>
            <a href={props.data.github} className="mx-2">
                <FontAwesomeIcon icon={faGithub} size="2x" />
            </a>
            <a href={props.data.linkedin} className="mx-2">
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>
            <a href={'mailto:' + props.data.email} className="mx-2">
                <FontAwesomeIcon icon={faAt} size="2x" />
            </a>
            <a href="/Resume.pdf" target="_blank" className="mx-2">
                <FontAwesomeIcon icon={faFile} size="2x" />
            </a>
        </>
    );

}

export default Socials;