const Navbar = () => {
    return (
        <nav className="navbar navbar-bg mb-4 pb-0">
            <div className="mx-auto col-12">
                <a className="navbar-brand" href="/"><h2>{"{"}Edward Menser{"}"}</h2></a>
            </div>
        </nav>
    );

}

export default Navbar;