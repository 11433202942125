import { useEffect, useState } from "react";
import Education from "./components/Education";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Experience from "./components/Experience";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";


import './App.css';

function App() {
  const data_urls = {
    "contact" : "https://t0y6xxhlg4.execute-api.us-east-1.amazonaws.com/Deploy/get/contact",
    "education" : "https://t0y6xxhlg4.execute-api.us-east-1.amazonaws.com/Deploy/get/education",
    "experience" : "https://t0y6xxhlg4.execute-api.us-east-1.amazonaws.com/Deploy/get/experience",
    "projects" : "https://t0y6xxhlg4.execute-api.us-east-1.amazonaws.com/Deploy/get/projects",
    "skills" : "https://t0y6xxhlg4.execute-api.us-east-1.amazonaws.com/Deploy/get/skills"
  };

  const [contact, setContact] = useState({});
  const [education, setEducation] = useState({});
  const [experience, setExperience] = useState({});
  const [projects, setProjects] = useState({});
  const [skills, setSkills] = useState({});


  const not_active = "nav-link collapsed";
  const is_active = "nav-link collapsed active";

  const [active, setActive] = useState({
      projects : is_active,
      skills : not_active,
      experience : not_active,
      education : not_active
  });

  const handleActive = (e, name) => {
    if (active[name] === is_active) {
        e.stopPropagation();
        return;
    }

    const reset = {
        projects : not_active,
        skills : not_active,
        experience : not_active,
        education : not_active
    };

    switch (name) {
        case "projects":
            reset.projects = is_active;
            setActive(reset);
            break;
        case "skills":
            reset.skills = is_active;
            setActive(reset);
            break;
        case "experience":
            reset.experience = is_active;
            setActive(reset);
            break;
        case "education":
            reset.education = is_active;
            setActive(reset);
            break;
    }
}

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async (section, url) => {
      const r = await fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        const data = json.body;
        if (isSubscribed) {
          switch(section) {
            case "contact":
              setContact(data);
              break;
            case "education":
              setEducation(data);
              break;
            case "experience":
              setExperience({experience:data});
              break;
            case "projects":
              setProjects({projects:data});
              break;
            case "skills":
              setSkills({skills:data});
              break;
            default:
              break;
          }
        }
      });
    }
    for (const key in data_urls) {
      if (data_urls.hasOwnProperty(key)) {
        fetchData(key, data_urls[key])
        .catch(console.error);
      }
    }
    return () => isSubscribed = false;
  }, []);

  return (
    <>
    <Navbar />
    
    <div className="container content">
      <div className="mx-auto">
        <ul className="nav nav-tabs">
            <li className="nav-item">
                <button className={active.projects} data-toggle="collapse" data-target="#projects" aria-expanded="false" aria-controls="projects" onClick={(e) => handleActive(e, "projects")}>Projects</button>
            </li>
            <li className="nav-item">
                <button className={active.skills} data-toggle="collapse" data-target="#skills" aria-expanded="false" aria-controls="skills" onClick={(e) => handleActive(e, "skills")}>Skills</button>
            </li>
            <li className="nav-item">
                <button className={active.experience} data-toggle="collapse" data-target="#experience" aria-expanded="false" aria-controls="experience" onClick={(e) => handleActive(e, "experience")}>Experience</button>
            </li>
            <li className="nav-item">
                <button className={active.education} data-toggle="collapse" data-target="#education" aria-expanded="false" aria-controls="education" onClick={(e) => handleActive(e, "education")}>Education</button>
            </li>
        </ul>
      </div>
      <div id="accordion">
        <div className="card no-border">
            <div id="skills" className="collapse" data-parent="#accordion">
              <div className="card-body">
                  <div className="row justify-content-center">
                    <Skills data={skills}/>
                  </div>
              </div>
            </div>
          </div>
          <div className="card no-border">
              <div id="experience" className="collapse" data-parent="#accordion">
                <div className="card-body">
                  <div className="row justify-content-center">
                    <Experience data={experience}/>
                  </div>
                </div>
              </div>
            </div>
          <div className="card no-border">
              <div id="projects" className="collapse show" data-parent="#accordion">
                <div className="card-body">
                  <div className="row justify-content-center">
                    <Projects data={projects}/>
                  </div>
                </div>
              </div>
            </div>
        <div className="card no-border">
          <div id="education" className="collapse" data-parent="#accordion">
            <div className="card-body">
              <div className="row justify-content-center">
                <Education data={education}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <Footer data={contact}/>
    </>
    

  );
}

export default App;
