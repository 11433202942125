const Experience = (props) => {
    if (props.data.experience === undefined) return;
    return (
        <>
            {props.data.experience.map(((exp, ind) => {
                return (
                    <div className="card col-sm-12 col-md-5 m-2 shadow" key={ind}>
                        <div className="card-body">
                            <h5 className="card-title">{exp.company}</h5>
                            <h6 className="card-subtitle mb-2">{exp.role}</h6>
                            <h6 className="card-subtitle mb-2 text-muted">{exp.location} <br/> {exp.start_date} - {exp.end_date}</h6>
                            <p className="card-text m-2">
                                <span className="text-justify">
                                    {exp.description}
                                </span>
                                <h6 className="card-subtitle mt-2">Accomplishments</h6>
                                <ul className="mt-1">
                                {exp.accomplishments.map(((acc, acc_ind) => {
                                    return (
                                        <li key={acc_ind} className="text-left mb-2">
                                            {acc}
                                        </li>
                                    )
                            
                                }))}
                                </ul>
                            </p>
                        </div>
                    </div>

                );
            }))}
        <div className="row col-12 m-2">
            <button className="btn btn-sm btn-secondary mx-auto shadow" 
            type="button" data-toggle="collapse" data-target="#apiExperienceResponse" aria-expanded="false" aria-controls="apiExperienceResponse">View API Response Data</button>    
        </div>
        <div className="row col-12 m-2">
            <div className="collapse col-12" id="apiExperienceResponse">
                <div className="card card-body col-12">
                    <pre className="rounded prettyprint lang-js">
                        {JSON.stringify(props.data.experience, null, 2)}
                    </pre>
                </div>
            </div>
        </div>
        </>
    );

}

export default Experience;