const Skills = (props) => {
    if (props.data.skills === undefined) return;
    return (
        <>
        
        <div className="row col-12">
            <div className="card col-sm-12 col-md-3 m-2 mx-auto shadow">
                <div className="card-body">
                    <h5 className="card-title">Expert</h5>
                    <h4>
                        {props.data.skills.Expert.map(expert => {
                                return (
                                    <span key={expert} className="badge badge-pill badge-success m-1">{expert}</span>
                                );
                            })}
                    </h4>
                </div>
            </div>

            <div className="card col-sm-12 col-md-4 m-2 mx-auto shadow">
                <div className="card-body">
                    <h5 className="card-title">Advanced</h5>
                    <h4>
                        {props.data.skills.Advanced.map(advanced => {
                                return (
                                    <span key={advanced} className="badge badge-pill badge-warning m-1">{advanced}</span>
                                );
                            })}
                    </h4>
                </div>
            </div>

            <div className="card col-sm-12 col-md-3 m-2 mx-auto shadow">
                <div className="card-body">
                    <h5 className="card-title">Beginner</h5>
                    <h4>
                        {props.data.skills.Beginner.map(beginner => {
                                return (
                                    <span key={beginner} className="badge badge-pill badge-danger m-1">{beginner}</span>
                                );
                            })}
                    </h4>
                </div>
            </div>
        </div>

        <div className="row col-12 m-2">
            <button className="btn btn-secondary mx-auto shadow" 
            type="button" data-toggle="collapse" data-target="#apiSkillsResponse" aria-expanded="false" aria-controls="apiSkillsResponse">View API Response Data</button>    
        </div>
        <div className="row col-12 m-2">
            <div className="collapse col-12" id="apiSkillsResponse">
                <div className="card card-body col-12">
                    <pre className="rounded prettyprint lang-js">
                        {JSON.stringify(props.data.skills, null, 2)}
                    </pre>
                </div>
            </div>
        </div>

        </>
    );

}

export default Skills;