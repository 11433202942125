import Socials from "./Socials";

const Footer = (props) => {
    return (
        <nav className="navbar fixed-bottom navbar-bg">
            <div className="mx-auto m-2">
                <Socials data={props.data}/>
            </div>
        </nav>
    );

}

export default Footer;